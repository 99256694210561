
import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
  props: [],

  created: ->
    @$store.commit('layout/page_nav', {
      title: 'Terms and conditions',
      back_target: '/'
    })

  methods: {
    build_item_marker: (section, item, sub_item) ->
      marker = "#{section}.#{item}"
      if !!sub_item then "#{marker}.#{sub_item}" else marker
    }

  computed: {
    lmfb_email: -> 'contact@lastmileforbop.com',
    lmfb_phone: -> parsePhoneNumber("+#{process.env.VUE_APP_SUPPORT_PHONE}").number.international,
    whitelabel_customer: -> @$store.getters['application/name']
    numbered_sections: -> [
      {
        heading: "Introduction: (The White Label Online Stores; this example is for #{@name} App)",
        items: [
          {
            text: "The #{@name} Online Store (referred to as the #{@name} Online Store), developed and operated by Last Mile for BoP, is a technological platform that enables users (Users/You/Your or Yourself) to buy a wide range of goods including groceries and other related products (“Goods”) sold by the #{@name} Supermarkets and delivered by cooperated carriers and with the administration of the #{@name} Online Store (together, referred to as the “Services”)."
          }
          {
            text: "These terms and conditions govern the ordering, sale, payment, collection and delivery of goods and the use of the App."
          }
          {
            text: "These terms and conditions are binding and enforceable against every person that accesses or uses the App (you/your/user). By using the App, you acknowledge that you have read and agree to be bound by these terms and conditions. You must not use the App if you do not agree to the Terms and Conditions."
          }
          {
            text: "NOTE: Please read these terms and conditions carefully. If you have any queries, please contact us at #{@lmfb_email}"
          }
          {
            text: "If any clause in these terms and conditions are invalid or illegal or cannot be enforced, those clauses will be severed and the remaining clauses will continue to be valid."
          }
        ]
      },
      {
        heading: "Registration:",
        items: [
          {
            text: "To buy Goods and use the main App’s services, users must register and maintain an active user account (“Account”). In the meantime, users are allowed to browse the app and you will need to register when you want to place an order."
          },
          {
            text: "You must be at least 18 years old to obtain an Account. By using the #{@name} Online Store, you warrant that you are 18 (eighteen) years old or older and of full legal capacity."
          },
          {
            text: "To register as a user, you must provide at least a phone number and a password. Other personal information is not mandatory for registration. You will need to use your phone number and password to sign into the App to be able to shop Goods."
          },
          {
            text: "You agree that, once the correct phone number and password relating to an account have been entered, irrespective of whether the use of the phone number and password is unauthorised or fraudulent, you will be liable for payment for Goods, save where the order is cancelled by you in accordance with these Terms."
          },
          {
            text: "For payment, paying online requires you to provide a credit and/or debit card or other form of payment information, which is used for payment once you place an order which is accepted. We do not store payment information. We use a payment gateway, which is a third-party partner. The third party operates the payment process for us and we do not have neither access nor stock of payment information."
          },
          {
            text: "You are responsible for all activity that occurs under your Account, and you agree to always maintain the security and secrecy of your Account phone number and password. We accept no liability for any loss suffered or incurred from the unauthorised use of your personal information, phone number or password."
          },
          {
            text: "You agree that You will use your phone number and password for your personal use only and will not disclose it to any third-party. If you do not use the correct phone number and password, You will not be able to access the #{@name} Online Store or use the services."
          },
          {
            text: "You agree to maintain accurate, complete and up-to-date information in your Account. Your failure to maintain accurate, complete and up-to-date Account information may result in your inability to access and use the services or our ending of these Terms with you. Unless otherwise permitted by us in writing, you may only possess one Account."
          },
          {
            text: "On receipt of your registration of an Account, the #{@name} Online Store, will take such steps, as in its sole discretion it deems necessary, to evaluate your registration to verify whether, in completing the registration, you have provided the #{@name} Online Store with complete and accurate information about yourself and you hereby agree to us performing such verification."
          },
          {
            text: "You agree to notify the #{@name} Online Store immediately upon becoming aware of or reasonably suspecting any unauthorised access to or use of your Account information (phone number and password) and to take steps to mitigate any resultant loss or harm by contacting us on #{@lmfb_phone} or #{@lmfb_email}}."
          },
          {
            text: "By creating an Account, you agree that we may send you:"
            items: [
              {
                text: "Push notifications which you agree to when downloading the App. You may turn these on or off from your mobile phone settings. You acknowledge that turning off push notifications may impact your use of the services."
              },
              {
                text: "Text (SMS) messages as part of the normal business operation of your use of the Services. You may opt-out of receiving text (SMS) messages from Us by following the directions in the SMS. You acknowledge that opting out of receiving text (SMS) messages may impact Your use of the Services."
              }
            ]
          }
        ]
      }
    ]
  }
}
